module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Stordjursveterinärerna i Varberg","short_name":"Stordjursveterinärerna","start_url":"/","background_color":"#ffffff","theme_color":"#698923","display":"standalone","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a98c5aa6a34faa7420157ce5caeb19c0"},
    },{
      plugin: require('../node_modules/gatsby-plugin-asset-path/gatsby-browser.js'),
      options: {"plugins":[],"fileTypes":["js","css","map","png"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://stordjursveterinärernaivarberg.se"},
    },{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"9","matomoUrl":"https://matomo.accodeing.com","siteUrl":"https://stordjursveterinärernaivarberg.se"},
    },{
      plugin: require('../node_modules/@accodeing/gatsby-theme-heimr/gatsby-browser.js'),
      options: {"plugins":[],"manifest":{"name":"Stordjursveterinärerna i Varberg","short_name":"Stordjursveterinärerna","background_color":"#ffffff","theme_color":"#698923"},"siteUrl":"https://stordjursveterinärernaivarberg.se","matomoSiteId":"9"},
    }]
